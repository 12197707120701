<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden z-50" @close="open = false" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />

        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900">
                        {{$t("installation.investorData")}}
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ifgreen" @click="open = false">
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <div class="space-y-2">
                        <InputWithError
                            v-model="name"
                            id="name"
                            name="name"
                            type="text"
                            autocomplete="name"
                            :label="$t('installation.customerName')"
                            :placeholder="$t('public.johnDoe')"
                            :errorText="$t('template.reqField')"
                            :validation="v$.name"
                        />
                        <InputWithError
                            v-model="company"
                            id="company"
                            name="company"
                            type="text"
                            autocomplete="company"
                            :label="$t('installation.companyName')"
                            :placeholder="$t('installation.companyName')"
                            errorText=""
                            :validation="v$.company"
                        />
                        <InputWithError
                            v-model="address"
                            id="address"
                            name="address"
                            type="text"
                            autocomplete="address"
                            :label="$t('installation.address')"
                            :placeholder="$t('installation.street')"
                            errorText=""
                            :validation="v$.address"
                        />
                        <InputWithError
                            v-model="city"
                            id="city"
                            name="city"
                            type="text"
                            autocomplete="city"
                            :label="$t('installation.addressContinued')"
                            :placeholder="$t('installation.city')"
                            errorText=""
                            :validation="v$.city"
                        />
                        <InputWithError
                            v-model="post_code"
                            id="post_code"
                            name="post_code"
                            type="text"
                            autocomplete="zip code"
                            :label="$t('installation.zipCode')"
                            placeholder="00-000"
                            errorText=""
                            :validation="v$.post_code"
                        />
                        <InputWithError
                            v-model="phone_number"
                            id="phone"
                            name="phone"
                            type="tel"
                            autocomplete="phone"
                            :label="$t('installation.phoneNumber')"
                            placeholder="+48000000000"
                            errorText=""
                            :validation="v$.phone_number"
                        />
                        <InputWithError
                            v-model="email"
                            id="email"
                            name="email"
                            type="email"
                            autocomplete="email"
                            label="Email"
                            placeholder="example@example.com"
                            errorText=""
                            :validation="v$.email"
                        />
                        <InputWithError
                            v-model="date"
                            id="date"
                            name="date"
                            type="date"
                            autocomplete="date"
                            :label="$t('installation.installationDate2')"
                            errorText=""
                            :validation="v$.date"
                        />
                        <div>
                            <label for="note" class="block text-sm font-medium text-gray-700">
                              {{$t("notes")}}
                            </label>
                            <div class="mt-1 relative">
                              <textarea
                                id="note"
                                name="note"
                                class="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-ifgreen focus:border-ifgreen sm:text-sm"
                                v-model="note"
                              />
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="open = false">
                    {{$t("cancel")}}
                  </button>
                  <button @click="submitInstallation" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-ifgreen hover:bg-ifgreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ifgreen">
                    {{$t("save")}}
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import InputWithError from '@/components/inputs/InputWithError.vue';
import useVuelidate from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import { api, patchHeaders } from "@/assets/js/api-client.js";
import { mapState } from 'vuex'

const phone_format = (value) => {return /^\+?[1-9]\d{1,14}$/.test(value)}
const post_format = (value) => {return /^\d{2}(?:[-\s]\d{3})?$/.test(value) }
const max_date = (value) => {return new Date(value).getTime() <= new Date().getTime()}

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        XMarkIcon,
        InputWithError,
    },
    emits: ['switch-loader'],
    setup () {
        return { v$: useVuelidate() }
    },
    props: {
      def_email: {
        type: String,
        default: ""
      },
      def_name: {
        type: String,
        default: ""
      },
      def_company: {
        type: String,
        default: ""
      },
      def_address: {
        type: String,
        default: ""
      },
      def_city: {
        type: String,
        default: ""
      },
      def_post_code: {
        type: String,
        default: ""
      },
      def_phone_number: {
        type: String,
        default: ""
      },
      def_date: {
        type: String,
        default: new Date().toLocaleString('sv').slice(0,10),
      },
      def_note: {
        type: String,
        default: ""
      },
      action: {
        type: String,
        default: "insert"
      }
    },
    data () {
        return {
            open: false,
            email: this.def_email,
            name: this.def_name,
            company: this.def_company,
            address: this.def_address,
            city: this.def_city,
            post_code: this.def_post_code,
            phone_number: this.def_phone_number,
            date: new Date(this.def_date).toLocaleString('sv').slice(0,10),
            note: this.def_note,
        }
    },
    validations () {
        return {
            email: {
                //required: helpers.withMessage('To pole jest wymagane', required),
                email: helpers.withMessage(`${this.$t('public.errors.email')}`, email)
            },
            name: { required: helpers.withMessage(`${this.$t('template.reqField')}`, required) },
            company: {
              // required: helpers.withMessage('To pole jest wymagane', required)
            },
            address: { required: helpers.withMessage(`${this.$t('template.reqField')}`, required) },
            city: { required: helpers.withMessage(`${this.$t('template.reqField')}`, required) },
            phone_number: {
                required: helpers.withMessage(`${this.$t('template.reqField')}`, required),
                phone_format: helpers.withMessage(`${this.$t('public.errors.phoneNumberFormat')}`, phone_format)
            },
            post_code: {
                required: helpers.withMessage(`${this.$t('template.reqField')}`, required),
                post_format: helpers.withMessage(`${this.$t('public.errors.zipCodeFormat')}`, post_format)
            },
            date: {
                required: helpers.withMessage(`${this.$t('template.reqField')}`, required),
                max_date: helpers.withMessage(`${this.$t('template.futureDate')}`, max_date)
            },
        }
    },
    computed: {
      ...mapState(['currentInstallation','user'])
    },
    methods: {
        show () {
            this.open = true
        },
        submitInstallation () {

          this.v$.$touch()
          if (this.v$.$error) return

          const data = {
            customerName: this.name,
            customerCompany: this.company,
            customerAddress: this.address,
            customerCity: this.city,
            customerZipcode: this.post_code,
            customerEmail: this.email,
            customerPhone: this.phone_number,
            installationDate: new Date(this.date).toLocaleString('sv').slice(0,10),
            remarks: this.note,
            organisation: `/organisations/${process.env.VUE_APP_ORG_ID}`
          }

          let method, url, headers

          if (this.action === 'update') {
            data['id'] = parseInt(this.$route.params.id)
            method = 'patch'
            url = `/installations/${data['id']}`
            headers = patchHeaders.headers
          } else if (this.action === 'insert') {
            data['status'] = 'no_device'
            if (this.user.asp) {
              data['receivers'] = [`/users/${this.user.asp.id}`]
            }
            method = 'post'
            url = '/installations'
            headers = {}
          }

          this.open = false
          this.$nextTick(() => { this.$store.dispatch('setLoading', true) })

          api.request({method, url, data, headers}).then(result => {
            this.$store.dispatch('getCollection', {entity: 'installations'})
            this.$store.dispatch('setLoading', false)
            this.action === 'update' ? this.open = false : this.$router.push({path: `/installations/${result.data.id}`})
          }).catch((error) => {
            console.log(error)
            this.$store.dispatch('setLoading', false)
            this.$notify({
              title: error.message,
              text: error.response.data.detail,
              type: 'error',
              duration: 5000
            })
          })
        },
    }
}
</script>
